import './polyfill';

import {
  qs,
  qsAll,
} from './dom-helpers';

import smoothAnchors from './smooth-anchors';
import CasesSlider from './modules/CasesSlider';
import Forms from './modules/Forms';
import ToTopButton from './modules/ToTopButton';
import Language from './modules/Language';

class Main {
  constructor() {
    this.forms = qsAll('.form');
    this._run();
  }

  _run() {
    try {
      this._registerGlobalObjects();
      new Forms();
      new CasesSlider();
      new ToTopButton();
      new Language();
      // eslint-disable-next-line new-cap
      new smoothAnchors();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerGlobalObjects() {
    window.qs = qs;
    window.qsAll = qsAll;
  }
}

document.addEventListener('DOMContentLoaded', () => new Main());
