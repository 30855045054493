import { tns } from 'tiny-slider';

import { qs } from '../dom-helpers';

class PlatformsSlider {
  constructor() {
    if (!qs('.screenshots__list')) return;
    this._run();
  }

  _run() {
    try {
      this._registerSlider();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerSlider() {
    tns({
      container: '.screenshots__list',
      slideBy: 1,
      items: 1,
      speed: 500,
      controls: false,
      nav: true,
      autoplayButton: false,
      gutter: 0,
      mouseDrag: true,
      swipeAngle: false,
      autoplay: true,
      autoplayTimeout: 5000,
      navAsThumbnails: true,
      responsive: {
        480: {
          items: 2,
          autoplayTimeout: 4000,
        },
        900: {
          items: 4,
          autoplayTimeout: 3000,
        },
      },
    });
  }
}

export default PlatformsSlider;
