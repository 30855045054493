import { qs } from '../dom-helpers';

class Language {
  constructor() {
    this.mainElement = qs('#main');
    this.emailField = qs('.sign-beta__field');
    this.czButton = qs('.lang-cz');
    this.enButton = qs('.lang-en');
    this.html = qs('html');
    this.textData = {
      en: {
        'hero-title': 'All you need for your pet in one place',
        'hero-desc': 'An all-encompassing pet platform that offers social spaces, real-time chats, carefully '
          + 'curated products, and expert services — creating a connected and supportive community for pets and '
          + 'their owners',
        'link-to-form': 'Sign up for Beta',
        'free-setup-title': 'Free application setup',
        'quick-access-title': 'Quick access to main services',
        'manage-title': 'Instant Ordering & Management',
        'features-title': 'App Features',
        'features-matching': 'Matching',
        'features-matching-desc': 'Experience a dating app-like interface for socializing your pet, making friends, '
          + 'or even finding a companion for your furry friend',
        'features-chats': 'Chats',
        'features-chats-desc': 'Effortlessly manage your interactions with other users, sellers, and service '
          + 'providers separately, all within a single, user-friendly interface',
        'features-products': 'Products & Services',
        'features-products-desc': 'Purchase essential products for your pets and access a diverse catalog to '
          + 'request various services and procedures',
        'features-all-in-one': 'All in One',
        'features-all-in-one-desc': 'With our app, you can seamlessly support various aspects of your pet\'s '
          + 'life without the need for multiple solutions',
        'next-coming-title': 'Coming Soon',
        'next-coming-desc': 'In our upcoming development plans, Taltalk is set to receive an array of enhanced '
          + 'features catering to diverse users, such as:',
        'next-coming-item-booking': 'Advanced services, encompassing booking and calendar functionalities for both '
          + 'users and providers',
        'next-coming-item-notifications': 'Smart notifications tailored to the specific events and needs of your '
          + 'pet\'s breed',
        'next-coming-item-medical-services': 'Medical and urgent services dedicated to your pet\'s well-being',
        'next-coming-item-market': 'Marketplace feature extension and advanced payment integration',
        'next-coming-item-reports': 'Comprehensive reports tailored for all user types',
        'next-coming-item-clinics': ' Specially designed features for veterinarian clinics and larger organizations',
        'next-coming-item-more': 'Stay tuned for continuous improvements, additional features, and enhancements '
          + 'inspired by valuable user feedback',
        'screenshots-title': 'App Screenshots',
        'sign-beta-title': 'Sing up for Beta!',
        'sign-beta-desc': 'Be among the first users to get the biggest amount of potential clients earlier. '
          + 'Get best options for socialization of your pet with fresh community',
        'sign-beta-label': 'Send your email',
        'sign-beta-submit-btn': 'Submit',
        'alert-error-title': 'An error occurred',
        'alert-error-desc': 'Something went wrong :( \n'
          + 'Try again',
        'alert-success-title': 'Success!',
        'alert-success-desc': 'The form has been successfully submitted, we will be in touch with you soon. Thank you!',
        'page-404-title': 'Page not found',
        'link-to-home': 'Home',
      },
      cz: {
        'hero-title': 'Vše, co potřebujete pro svého domácího mazlíčka, na jednom místě',
        'hero-desc': 'Komplexní platforma pro domácí mazlíčky, která poskytuje prostor pro socializaci, '
          + 'chaty, pečlivě vybrané produkty a profesionální služby pro komunitu milovníků domácích mazlíčků',
        'link-to-form': 'Zaregistrujte se do Beta verze',
        'free-setup-title': 'Bezplatná verze aplikace',
        'quick-access-title': 'Okamžité objednávání a správa',
        'manage-title': 'Rychlý přístup k hlavním službám',
        'features-title': 'Funkce aplikace',
        'features-matching': 'Systém dopasování',
        'features-matching-desc': 'Podobné zážitky jako s aplikacemi pro seznamování, hledání nových přátel, nebo '
          + 'dokonce najít společníka pro vášeho domácího mazlíčka',
        'features-chats': 'Chatování',
        'features-chats-desc': 'Komunikujte s ostatními uživateli, prodejce a poskytovatele služeb',
        'features-products': 'Produkty a služby',
        'features-products-desc': 'Nakupujte potřebné produkty pro své domácí mazlíčky a vybírejte ze širokého '
          + 'katalogu služeb a procedur',
        'features-all-in-one': 'Vše v jednom',
        'features-all-in-one-desc': 'S naší aplikací můžete podporovat různé aspekty života vašeho domácího '
          + 'mazlíčka, aniž byste museli používat balíček různých řešení',
        'next-coming-title': 'Již brzy',
        'next-coming-desc': 'V rámci následujících plánů vývoje bude aplikace Taltalk rozšířena o ještě více funkcí'
          + ' pro různé uživatele, včetně:',
        'next-coming-item-booking': 'Pokročilé služby včetně rezervací a kalendáře pro uživatele a poskytovatele',
        'next-coming-item-notifications': 'Chytrá oznámení na základě událostí a potřeb konkrétního plemene vašeho '
          + 'domácího mazlíčka',
        'next-coming-item-medical-services': 'Lékařské a urgentní služby pro vaše zvíře',
        'next-coming-item-market': 'Rozšíření funkcí tržiště a pokročilá integrace plateb',
        'next-coming-item-reports': 'Pokročilé přehledy pro všechny typy uživatelů',
        'next-coming-item-clinics': 'Funkce pro veterinární kliniky a větší organizace',
        'next-coming-item-more': 'Další vylepšení, funkce a zdokonalení na základě zpětné vazby uživatelů',
        'screenshots-title': 'Snímky obrazovky aplikace',
        'sign-beta-title': 'Přihlaste se k Beta verzi!',
        'sign-beta-desc': 'Buďte mezi prvními uživateli a získejte dříve největší množství potenciálních klientů. '
          + 'Získejte nejlepší možnosti socializace svého mazlíčka s čerstvou komunitou',
        'sign-beta-label': 'Pošlete svůj e-mail',
        'sign-beta-submit-btn': 'Odeslat',
        'alert-error-title': '',
        'alert-error-desc': 'Formulář se nepodařilo odeslat :( \n'
          + 'Zkuste to znovu',
        'alert-success-title': 'Úspěšně!',
        'alert-success-desc': 'Formulář byl úspěšně odeslán, brzy vás budeme kontaktovat. Děkujeme!',
        'page-404-title': 'Stránka nenalezena',
        'link-to-home': 'Na hlavní stránku',
      },
    };

    this.currentLanguage = 'en'; // Изначально английский
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
      this._changeLanguage();
      this._updateActiveLangButton();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    this._addEventListenersForCzech();
    this._addEventListenersForEnglish();
  }

  _addEventListenersForCzech() {
    this.czButton.addEventListener('click', () => {
      this.currentLanguage = 'cz';
      this._changeLanguage();
      this.emailField.setAttribute('placeholder', 'Váš email');
      this.html.setAttribute('lang', 'cz');
      this._updateActiveLangButton();
    });
  }

  _addEventListenersForEnglish() {
    this.enButton.addEventListener('click', () => {
      this.currentLanguage = 'en';
      this._changeLanguage();
      this.emailField.setAttribute('placeholder', 'Your email');
      this.html.setAttribute('lang', 'en');
      this._updateActiveLangButton();
    });
  }

  _changeLanguage() {
    const lang = this.currentLanguage;
    const textData = this.textData[lang];

    for (const key in textData) {
      if (textData.hasOwnProperty.call(textData, key)) {
        const element = qs(`[data-translate="${key}"]`);
        if (element) {
          element.textContent = textData[key];
        }
      }
    }
  }

  _updateActiveLangButton() {
    if (this.currentLanguage === 'en') {
      this.enButton.classList.add('is-active-lang');
      this.czButton.classList.remove('is-active-lang');
    } else {
      this.czButton.classList.add('is-active-lang');
      this.enButton.classList.remove('is-active-lang');
    }
  }
}

export default Language;
