import { qs, qsAll } from '../dom-helpers';

class Forms {
  constructor() {
    this.form = qs('.sign-beta__form');
    this.submitButton = qs('.sign-beta__submit-button');
    this.contactInput = qs('.input-contact');
    this.alertNotification = qsAll('.alert');
    this.alertError = qs('.alert--error');
    this.alertSuccess = qs('.alert--success');
    this.alertCloseButton = qsAll('.alert__close-button');
    this._run();
  }

  _run() {
    try {
      this._registerEventListeners();
    } catch (e) {
      console.error(e); // eslint-disable-line
    }
  }

  _registerEventListeners() {
    if (this.form) {
      this.form.addEventListener('submit', (event) => {
        event.preventDefault();

        if (this._validate()) {
          const formData = new FormData(this.form);

          fetch('../../sendmail.php', {
            method: 'POST',
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
              console.log('Success'); // eslint-disable-line
                this.alertSuccess.classList.add('alert--visible');
                setTimeout(() => {
                  this.alertSuccess.classList.remove('alert--visible');
                }, 5000);
              } else {
              console.log('Error'); // eslint-disable-line
                this.alertError.classList.add('alert--visible');
                setTimeout(() => {
                  this.alertError.classList.remove('alert--visible');
                }, 5000);
              }
            })
            .catch((error) => {
            console.log('Error', error); // eslint-disable-line
              this.alertError.classList.add('alert--visible');
              setTimeout(() => {
                this.alertError.classList.remove('alert--visible');
              }, 5000);
            });
        // this.form.submit();
        }

        if (!this._validate()) {
          console.log('Error'); // eslint-disable-line
          this.alertError.classList.add('alert--visible');
          setTimeout(() => {
            this.alertError.classList.remove('alert--visible');
          }, 5000);
        }
      });
    }

    this.alertNotification.forEach((alertNotification) => {
      this.alertCloseButton.forEach((closeButton) => {
        closeButton.addEventListener('click', () => {
          alertNotification.classList.remove('alert--visible');
        });
      });
    });
  }

  _validate() {
    let valid = true;

    if (!this._validateContact()) {
      valid = false;
    }

    return valid;
  }

  _validateContact() {
    const contactValue = this.contactInput.value.trim();
    const contactRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

    if (contactValue === '') {
      return false;
    }

    if (!contactRegex.test(contactValue)) {
      return false;
    }
    return true;
  }
}

export default Forms;

// _registerEventListeners() {
//   if (this.form) {
//     this.form.addEventListener('submit', (event) => {
//       event.preventDefault();
//
//       if (this._validate()) {
//         const formData = new FormData(this.form);
//
//         fetch('path/to/server', {
//           method: 'POST',
//           body: formData,
//         })
//           .then((response) => {
//             if (response.ok) {
//               console.log('Success'); // eslint-disable-line
//               this.alertSuccess.classList.add('alert--visible');
//               setTimeout(() => {
//                 this.alertSuccess.classList.remove('alert--visible');
//               }, 5000);
//             } else {
//               console.log('Error'); // eslint-disable-line
//               this.alertError.classList.add('alert--visible');
//               setTimeout(() => {
//                 this.alertError.classList.remove('alert--visible');
//               }, 5000);
//             }
//           })
//           .catch((error) => {
//             console.log('Error', error); // eslint-disable-line
//             this.alertError.classList.add('alert--visible');
//             setTimeout(() => {
//               this.alertError.classList.remove('alert--visible');
//             }, 5000);
//           });
//         // this.form.submit();
//       }
//     });
//   }
//
//   this.alertNotification.forEach((alertNotification) => {
//     this.alertCloseButton.forEach((closeButton) => {
//       closeButton.addEventListener('click', () => {
//         alertNotification.classList.remove('alert--visible');
//       });
//     });
//   });
// }
//
// _validate() {
//   let valid = true;
//
//   if (!this._validateContact()) {
//     valid = false;
//   }
//
//   return valid;
// }
//
// _validateContact() {
//   const contactValue = this.contactInput.value.trim();
//   const contactRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
//   if (contactValue === '') {
//     return false;
//   }
//
//   if (!contactRegex.test(contactValue)) {
//     return false;
//   }
//   return true;
// }

// _registerEventListeners() {
//   if (this.form) {
//     this.form.addEventListener('submit', (event) => {
//       event.preventDefault();
//
//       if (this._validate()) {
//         this.alertSuccess.classList.add('alert--visible');
//         setTimeout(() => {
//           this.alertSuccess.classList.remove('alert--visible');
//         }, 5000);
//       }
//
//       if (!this._validate()) {
//         this.alertError.classList.add('alert--visible');
//         setTimeout(() => {
//           this.alertError.classList.remove('alert--visible');
//         }, 5000);
//       }
//     });
//   }
//
//   this.alertNotification.forEach((alertNotification) => {
//     this.alertCloseButton.forEach((closeButton) => {
//       closeButton.addEventListener('click', () => {
//         alertNotification.classList.remove('alert--visible');
//       });
//     });
//   });
// }
